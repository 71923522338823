import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useCandidateContext } from '../../context/CandidateContext'
import { getFilterOptions, IFilterSuggestion } from '../../services/JobService'
import { encodeFilter } from '../../utils/functions'

export default function Offers() {
  const { email } = useParams()
  const navigate = useNavigate()
  const candidateContext = useCandidateContext()

  const goToJobs = (filterData: IFilterSuggestion | undefined) => {
    if (filterData === undefined) {
      return navigate('/')
    }
    try {
      const jobRoles = filterData.qualification?.split(',') ?? []
      const filterQuery = encodeFilter(
        filterData?.city ?? null,
        filterData.radius,
        jobRoles,
        filterData.shiftTags
      )
      navigate(`/?${filterQuery}`)
    } catch (err) {
      navigate('/')
    }
  }

  useEffect(() => {
    if (email) {
      getFilterOptions(email).then(
        (res) => {
          const data = res.data.data
          candidateContext.initFromOffers(
            data.candidateId ?? null,
            data.email,
            data.appliedJobIds,
            data.qualification ?? null
          )
          goToJobs(data)
        },
        (_err) => {
          goToJobs(undefined)
        }
      )
    } else {
      goToJobs(undefined)
    }
  }, [])

  return (
    <div className="row">
      <div className="col mt-5 text-center">
        <h5>Hang on, we are looking for jobs just for you...</h5>
      </div>
    </div>
  )
}
