import { formatNumberToPrice } from '../../utils/functions'

type PayType = {
  weeklyTeamPay: number
  clientSupportPay: number
  tenureBonus: number
  referralBonus: number
  paidTimeOff: number
}

const estimatedPayTable: Record<string, PayType> = {
  HHA: {
    weeklyTeamPay: 50,
    clientSupportPay: 1.8,
    tenureBonus: 200,
    referralBonus: 400,
    paidTimeOff: 40
  },
  CNA: {
    weeklyTeamPay: 50,
    clientSupportPay: 1.8,
    tenureBonus: 200,
    referralBonus: 400,
    paidTimeOff: 40
  },
  LPN: {
    weeklyTeamPay: 50,
    clientSupportPay: 1.8,
    tenureBonus: 200,
    referralBonus: 400,
    paidTimeOff: 40
  },
  RN: {
    weeklyTeamPay: 50,
    clientSupportPay: 1.8,
    tenureBonus: 200,
    referralBonus: 400,
    paidTimeOff: 40
  }
}

export default function EstimatedPayTable(props: {
  qualifications: string
  regularPayRate: number
}) {
  const payObjectKey = estimatedPayTable[props.qualifications.toUpperCase()]

  return (
    <div>
      <p className="my-1">
        <span className="text-start fw-bold">
          Effective Pay Rate Paid Breakdown
        </span>
      </p>

      <ul className="ps-0">
        <div className="border-bottom border-dark border-1 mb-2 pb-2">
          <li className=" d-flex justify-content-between">
            <span>Regular Pay Rate</span>
            {formatNumberToPrice(props.regularPayRate, '/hour')}
          </li>
          <li className=" d-flex justify-content-between">
            <span>Weekly Team Pay</span>
            {formatNumberToPrice(payObjectKey.weeklyTeamPay, '/week')}
          </li>
          <li className=" d-flex justify-content-between">
            <span>Client Support</span>
            {formatNumberToPrice(payObjectKey.clientSupportPay, '/week/client')}
          </li>
        </div>
        <li className=" d-flex justify-content-between fw-bold">
          <span>Estimated Effective Pay Rate</span>{' '}
          {formatNumberToPrice(
            CalcEstimatedPayRate(
              props.qualifications.toUpperCase(),
              props.regularPayRate
            ),
            '/h'
          )}
        </li>
        <li
          className=" d-flex justify-content-between"
          style={{ fontSize: '0.7em' }}
        >
          Assuming, for example, that your team delivered 30h or more of patient
          care, and that you are supporting 2 clients for 30h/week i.e.{' '}
          {DescribeCalcEstimatedPayRate(
            props.qualifications.toUpperCase(),
            props.regularPayRate
          )}
        </li>
      </ul>

      <p className="my-1">
        <span className="text-start fw-bold">
          Additional Guaranteed Bonuses
        </span>
      </p>

      <ul className="ps-0">
        <div className="border-bottom border-dark border-1 mb-2 pb-2">
          <li className=" d-flex justify-content-between">
            <span>Referral Bonus</span>
            {formatNumberToPrice(
              payObjectKey.referralBonus,
              ' per hired referral'
            )}
          </li>
          <li className=" d-flex justify-content-between">
            <span>Paid Time Off</span>
            {formatNumberToPrice(
              payObjectKey.paidTimeOff,
              'h accrued at base rate, cashable',
              ''
            )}
          </li>
          <li className=" d-flex justify-content-between">
            <span>Service Continuity Pay</span>
            {formatNumberToPrice(
              payObjectKey.tenureBonus,
              '+ for every 500h milestone'
            )}
          </li>
          <li
            className=" d-flex justify-content-between"
            style={{ fontSize: '0.7em' }}
          >
            This is the pay that is paid to every employee for every 500 hours
            of in-home services delivered. It is a flat $200 pay out for every
            milestone. An additional $300 is paid for every 2000 hours of
            in-home services delivered
          </li>
        </div>
      </ul>
    </div>
  )
}

export function CalcEstimatedPayRate(
  qualifications: string,
  regularPayRate: number
) {
  const payObjectKey = estimatedPayTable[qualifications.toUpperCase()]

  // Calculate and return estitamted pay rate
  const effectivePayRate =
    (30 * regularPayRate +
      payObjectKey.weeklyTeamPay +
      2 * 30 * payObjectKey.clientSupportPay) /
    30
  return effectivePayRate
}

export function DescribeCalcEstimatedPayRate(
  qualifications: string,
  regularPayRate: number
) {
  const payObjectKey = estimatedPayTable[qualifications.toUpperCase()]

  // Calculate and return estitamted pay rate
  const effectivePayRateDescr = `${formatNumberToPrice(
    regularPayRate,
    ''
  )} + ${formatNumberToPrice(
    payObjectKey.weeklyTeamPay,
    ''
  )}÷30h + 2×${formatNumberToPrice(
    payObjectKey.clientSupportPay,
    ''
  )} = ${formatNumberToPrice(
    CalcEstimatedPayRate(qualifications, regularPayRate),
    '/hour'
  )}`
  return effectivePayRateDescr
}
