import React, { useState, useEffect, FC, useReducer, useContext } from 'react'
import {
  getJobsPaginated,
  IPaginatedJob,
  PreferredShifts
} from '../services/JobService'

export interface IFilterContext {
  city: string | null
  setCity: (city: string | null) => void

  radius: number
  setRadius: (radius: number) => void

  jobRoles: string[]
  setJobRoles: (roles: string[]) => void

  shiftTags: string[]
  setShiftTags: (shiftTags: string[]) => void

  preferredShifts: PreferredShifts
  setPreferredShifts: (shifts: PreferredShifts) => void

  jobs: IPaginatedJob[]
  setJobs: (jobs: IPaginatedJob[]) => void

  jobCount: number
  setJobCount: (count: number) => void

  currentPage: number
  setCurrentPage: (page: number) => void

  pagesTotal: number
  setPagesTotal: (pages: number) => void

  allCities: string[]
  setAllCities: (cities: string[]) => void

  allRadiuses: number[]
  setAllRadiuses: (radiuses: number[]) => void

  allJobRoles: string[]
  setAllJobRoles: (jobRoles: string[]) => void

  returnHighestJobRole: () => string

  isLoading: boolean
}

function useFilterContext() {
  const context = useContext(FilterContext)
  if (context == null) {
    throw new Error("ERROR: Using FilterContext while it's null")
  }
  return context
}

const FilterContext = React.createContext<IFilterContext | null>(null)

export const emptyShifts: PreferredShifts = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: []
}

const initialFilterState = {
  city: null,
  radius: 25,
  jobRoles: [],
  currentPage: 1,
  preferredShifts: emptyShifts,
  shiftTags: []
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filterReducer(state: any, action: any) {
  switch (action.type) {
    case 'city': {
      const { city } = action
      return {
        ...state,
        currentPage: 1,
        city: city
      }
    }

    case 'radius': {
      const { radius } = action
      return {
        ...state,
        currentPage: 1,
        radius: radius
      }
    }

    case 'jobRoles': {
      const { jobRoles } = action
      return {
        ...state,
        currentPage: 1,
        jobRoles: jobRoles
      }
    }

    case 'currentPage': {
      const { newPage } = action
      return {
        ...state,
        currentPage: newPage
      }
    }

    case 'shifts': {
      const { shifts } = action
      return {
        ...state,
        currentPage: 1,
        preferredShifts: shifts
      }
    }

    case 'shiftTags': {
      const { shiftTags } = action
      return {
        ...state,
        currentPage: 1,
        shiftTags: shiftTags
      }
    }
  }
  return initialFilterState
}

const initialContentState = {
  jobs: [],
  jobCount: 0,
  pagesTotal: 1,
  isLoading: false
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function contentReducer(state: any, action: any) {
  switch (action.type) {
    case 'jobs': {
      const { jobs } = action
      return {
        ...state,
        jobs: jobs
      }
    }
    case 'jobCount': {
      const { jobCount } = action
      return {
        ...state,
        jobCount: jobCount
      }
    }
    case 'pagesTotal': {
      const { pagesTotal } = action
      return {
        ...state,
        pagesTotal: pagesTotal
      }
    }
    case 'isLoading': {
      const { isLoading } = action
      return {
        ...state,
        isLoading: isLoading
      }
    }
    case 'append': {
      return {
        ...state,
        jobs: state.jobs.concat(action.jobs),
        jobCount: action.jobCount,
        pagesTotal: action.pagesTotal,
        isLoading: action.isLoading
      }
    }
  }

  return initialContentState
}

const FilterProvider: FC = ({ children }) => {
  const [allCities, setAllCities] = useState<string[]>([])
  const [allRadiuses, setAllRadiuses] = useState<number[]>([10, 15, 25, 50])
  const [allJobRoles, setAllJobRoles] = useState<string[]>([
    'RN',
    'LPN',
    'CNA',
    'HHA'
  ])

  const [contentState, contentDispatch] = useReducer(
    contentReducer,
    initialContentState
  )
  const jobs = contentState.jobs
  const jobCount = contentState.jobCount
  const pagesTotal = contentState.pagesTotal
  const isLoading = contentState.isLoading

  const setJobs = (jobs: IPaginatedJob[]) => {
    contentDispatch({ type: 'jobs', jobs })
  }

  const setJobCount = (jobCount: number) => {
    contentDispatch({ type: 'jobCount', jobCount })
  }

  const setPagesTotal = (pagesTotal: number) => {
    contentDispatch({ type: 'pagesTotal', pagesTotal })
  }

  const setIsLoading = (isLoading: boolean) => {
    contentDispatch({ type: 'isLoading', isLoading })
  }

  const [filterState, filterDispatch] = useReducer(
    filterReducer,
    initialFilterState
  )

  const city = filterState.city ? filterState.city.toString() : null
  const patientZip =
    filterState.city && filterState.city.toString().match(/^\d+$/)
      ? filterState.city.toString()
      : null
  const radius = filterState.radius
  const jobRoles = filterState.jobRoles
  const shiftTags = filterState.shiftTags
  const preferredShifts = filterState.preferredShifts
  const currentPage = filterState.currentPage

  const setCity = (city: string | null) => {
    filterDispatch({ type: 'city', city })
  }

  const setRadius = (radius: number) => {
    filterDispatch({ type: 'radius', radius })
  }

  const setJobRoles = (jobRoles: string[]) => {
    filterDispatch({ type: 'jobRoles', jobRoles })
  }

  const setShiftTags = (shiftTags: string[]) => {
    filterDispatch({ type: 'shiftTags', shiftTags })
  }

  const setPreferredShifts = (shifts: PreferredShifts) => {
    filterDispatch({ type: 'shifts', shifts })
  }

  const setCurrentPage = (newPage: number) => {
    if (isLoading === false) {
      filterDispatch({ type: 'currentPage', newPage })
    }
  }

  const returnHighestJobRole = (): string => {
    const ranks: Record<string, number> = {
      RN: 0,
      LPN: 1,
      CNA: 2,
      HHA: 3
    }
    if (jobRoles.length === 0) return 'Qualifications'

    const highestRanks = jobRoles.reduce((acc: string, curr: string) => {
      return ranks[curr] < ranks[acc] ? curr : acc
    })

    if (jobRoles.length > 1) {
      return `${highestRanks} + ${jobRoles.length - 1}`
    }

    return highestRanks
  }

  useEffect(() => {
    console.log('filter changed', city, radius, jobRoles, currentPage)
    if (currentPage == 1) {
      setJobs([])
      setJobCount(0)
      setPagesTotal(0)
    }
    const controller = new AbortController()
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const res = await getJobsPaginated(
          city,
          patientZip,
          jobRoles,
          preferredShifts,
          shiftTags,
          radius,
          currentPage,
          25,
          controller
        )
        contentDispatch({
          type: 'append',
          jobs: res.data.data,
          isLoading: false,
          jobCount: res.data.pagination.totalItems,
          pagesTotal: res.data.pagination.totalPages
        })
      } catch (err) {
        console.log(err)
      }
    }

    fetchData()

    return () => {
      console.log('request aborted')
      controller.abort()
    }
  }, [filterState])

  const value = {
    city,
    setCity,
    radius,
    setRadius,
    jobRoles,
    setJobRoles,
    preferredShifts,
    setPreferredShifts,
    shiftTags,
    setShiftTags,
    jobs,
    setJobs,
    jobCount,
    setJobCount,
    currentPage,
    setCurrentPage,
    pagesTotal,
    setPagesTotal,
    allCities,
    setAllCities,
    allRadiuses,
    setAllRadiuses,
    allJobRoles,
    setAllJobRoles,
    returnHighestJobRole,
    isLoading
  }

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  )
}

export { FilterProvider, FilterContext, useFilterContext }
