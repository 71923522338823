import { Link } from 'react-router-dom'
import JobCard from '../../components/JobCard/JobCard'
import SearchBar from '../../components/SearchBar/SearchBar'
import './JobsList.css'
import { useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useFilterContext } from '../../context/FilterContext'
import { decodeFilter, encodeFilter } from '../../utils/functions'
import Preloader from '../../components/Preloader/Preloader'
import { useCandidateContext } from '../../context/CandidateContext'
import { IPaginatedJob } from '../../services/JobService'
import React from 'react'

export default function JobsList() {
  const filterContext = useFilterContext()
  const candidateContext = useCandidateContext()

  useEffect(() => {
    const f = decodeFilter(window.location.search)
    filterContext.setCity(f[0])
    filterContext.setRadius(f[1])
    filterContext.setJobRoles(
      candidateContext.qualification
        ? [candidateContext.qualification.toUpperCase()]
        : f[2]
    )
    filterContext.setShiftTags(f[3])
  }, [])

  useEffect(() => {
    const f = encodeFilter(
      filterContext.city,
      filterContext.radius,
      filterContext.jobRoles,
      filterContext.shiftTags
    )
    window.history.pushState(null, '', `?${f}`)
  }, [
    filterContext.city,
    filterContext.radius,
    filterContext.jobRoles,
    filterContext.shiftTags
  ])

  const JobCardContainer = (props: {
    job: IPaginatedJob
    hasApplied: boolean
  }) => {
    const { job, hasApplied } = props
    return (
      <div className={`col-12 mb-4 col-sm-6 col-lg-4 col-xxl-3`}>
        <Link
          to={`/job/${job.externalJobId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <JobCard job={job} hasApplied={hasApplied} />
        </Link>
      </div>
    )
  }

  const makeJobList = () => {
    const highPriorityJobs = filterContext.jobs.filter(
      (j) => j.isAvailabilityConfirmed == true && j.hasFamilyInfo == true
    )
    return (
      <>
        {highPriorityJobs.length > 0 && (
          <React.Fragment>
            <div className="row">
              <div className="col">
                <p className="text-start text-muted">
                  {highPriorityJobs.length} High Priority Jobs
                </p>
              </div>
            </div>
            <div className="row">
              {highPriorityJobs.map((job: IPaginatedJob, idx: number) => {
                const hasApplied = candidateContext.checkifAlreadyApplied(
                  job.externalJobId
                )
                return (
                  <JobCardContainer
                    job={job}
                    key={idx}
                    hasApplied={hasApplied}
                  />
                )
              })}
            </div>
          </React.Fragment>
        )}
        <div className="row">
          <div className="col">
            <p className="text-start text-muted">
              {filterContext.jobCount} Total Jobs
            </p>
          </div>
        </div>
        <div className="row">
          {filterContext.jobs.map((job: IPaginatedJob, idx: number) => {
            const hasApplied = candidateContext.checkifAlreadyApplied(
              job.externalJobId
            )
            return (
              <JobCardContainer job={job} key={idx} hasApplied={hasApplied} />
            )
          })}
        </div>
      </>
    )
  }

  const fetchMoreData = () => {
    const currentPage = filterContext.currentPage
    filterContext.setCurrentPage(currentPage + 1)
  }

  const EmptyMessage = () => {
    return (
      <div className="row justify-content-center">
        <div className="col-8 text-center mt-5">
          <h5>
            Sorry, we were unable to find any jobs that fit your criteria.
          </h5>
          <h5>Try changing the city or the range you are willing to travel.</h5>
        </div>
      </div>
    )
  }

  const JobScroller = () => {
    return (
      <div className="row">
        <div className="col">
          <InfiniteScroll
            dataLength={filterContext.jobs.length} //This is important field to render the next data
            next={fetchMoreData}
            hasMore={filterContext.pagesTotal != filterContext.currentPage}
            loader={<Preloader />}
            endMessage={null}
          >
            {makeJobList()}
          </InfiniteScroll>
        </div>
      </div>
    )
  }

  return (
    <div className="JobsList ">
      <div className="row sticky-top" style={{ backgroundColor: 'white' }}>
        <div className="col-md-9 col-xl-6 m-md-auto">
          <SearchBar />
        </div>
      </div>
      {filterContext.jobCount == 0 && !filterContext.isLoading ? (
        <EmptyMessage />
      ) : (
        <JobScroller />
      )}
    </div>
  )
}
