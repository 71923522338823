import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useReducer
} from 'react'
import { useLocalStorage } from 'react-use'
import { getFilterOptions } from '../services/JobService'

export interface ICandidateContext {
  candidateId: string | null
  email: string | null
  alreadyAppliedJobsId: string[]
  checkifAlreadyApplied: (jobId: string) => boolean
  addNewAppliedJobId: (jobId: string) => void
  qualification: string | null
  initFromOffers: (
    candidateId: string | null,
    email: string | null,
    alreadyAppliedJobsId: string[],
    qualification: string | null
  ) => void
}

function useCandidateContext(): ICandidateContext {
  const context = useContext(CandidateContext)
  if (context == null) {
    throw new Error("ERROR: Using CandidateContext while it's null")
  }
  return context
}

const CandidateContext = React.createContext<ICandidateContext | null>(null)
const CandidateProvider: FC = ({ children }) => {
  const LOCAL_STORAGE_KEY = 'candidateContext'
  const INITIAL_STATE = {
    candidateId: null,
    email: null,
    alreadyAppliedJobsId: [],
    qualification: null
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case 'initFromOffers': {
        const { candidateId, alreadyAppliedJobsId, email, qualification } =
          action
        return {
          ...state,
          candidateId: candidateId,
          email: email,
          alreadyAppliedJobsId: alreadyAppliedJobsId,
          qualification: qualification
        }
      }
      case 'addAppliedJobId': {
        const { newJobId } = action
        return {
          ...state,
          alreadyAppliedJobsId: state.alreadyAppliedJobsId.concat(newJobId)
        }
      }
    }
    return INITIAL_STATE
  }

  const usePersistReducer = () => {
    // grab saved value from `localStorage` and
    // a function to update it. if
    // no value is retrieved, use `INITIAL_STATE`
    const [savedState, saveState] = useLocalStorage(
      LOCAL_STORAGE_KEY,
      INITIAL_STATE
    )

    const reducerLocalStorage = useCallback(
      (state, action) => {
        const newState = reducer(state, action)

        saveState(newState)

        return newState
      },
      [saveState]
    )
    return useReducer(reducerLocalStorage, savedState)
  }

  const [candidateState, candidateDispatch] = usePersistReducer()

  const candidateId = candidateState.candidateId
  const email = candidateState.email
  const alreadyAppliedJobsId = candidateState.alreadyAppliedJobsId
  const qualification = candidateState.qualification

  const checkifAlreadyApplied = (jobID: string) => {
    if (alreadyAppliedJobsId) {
      return alreadyAppliedJobsId.includes(jobID)
    } else {
      return false
    }
  }

  const addNewAppliedJobId = (jobId: string) => {
    candidateDispatch({
      type: 'addAppliedJobId',
      newJobId: jobId
    })
  }

  const initFromOffers = (
    candidateId: string | null,
    email: string | null,
    alreadyAppliedJobsId: string[],
    qualification: string | null
  ) => {
    candidateDispatch({
      type: 'initFromOffers',
      candidateId,
      email,
      alreadyAppliedJobsId,
      qualification
    })
  }

  useEffect(() => {
    console.log('candidate context loaded')
    if (email !== null) {
      getFilterOptions(email).then((res) => {
        const data = res.data.data
        initFromOffers(
          data.candidateId ?? null,
          data.email,
          data.appliedJobIds,
          data.qualification ?? null
        )
      })
    }
  }, [])

  const value = {
    email,
    candidateId,
    alreadyAppliedJobsId,
    checkifAlreadyApplied,
    addNewAppliedJobId,
    initFromOffers,
    qualification
  }

  return (
    <CandidateContext.Provider value={value}>
      {children}
    </CandidateContext.Provider>
  )
}

export { CandidateProvider, CandidateContext, useCandidateContext }
